import React, {Fragment} from 'react'
import {withRouter} from 'react-router-dom'
import MetisMenu from 'react-metismenu'

import {useAuth} from "../../Context"
import {HomeNav, SlePle51Nav, SireNav} from "./navItems"

const Nav = () => {
    const {auth, setAuth} = useAuth()

    if (!auth.authentication) {
        setAuth({})
        window.location.href = '/login'
    }

    return (
        <Fragment>
            <MetisMenu content={HomeNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>

            <h5 className={`app-sidebar__heading`}>SIRE SUNAT</h5>
            <MetisMenu content={SireNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>

            <h5 className={`app-sidebar__heading`}>LIBROS ELECTRÓNICOS (SLE-PLE 5.2)</h5>
            <MetisMenu className="vertical-nav-menu" content={SlePle51Nav} activeLinkFromLocation iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>
        </Fragment>
    )
}

export default withRouter(Nav)
