import React, {Fragment} from 'react'
import {DropdownToggle, DropdownMenu, Nav, Button, NavItem, UncontrolledButtonDropdown} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faPowerOff} from '@fortawesome/free-solid-svg-icons'
import UserAvatar from "react-user-avatar"
import {Link} from "react-router-dom"
import {faUserCircle} from "@fortawesome/fontawesome-free-solid"

import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg'
import {useAuth} from "../../../Context"
import {decodeToken} from "../../../utils/scripts"
// import ThemeOptions from "../../ThemeOptions"

const UserBox = () => {
    const {auth, setAuth} = useAuth()

    const {authentication} = auth
    const {sub, email} = decodeToken(authentication)

    const logOut = () => {
        setAuth({})
        window.location.href = '/login'
    }

    return (
        <Fragment>
            <div className="header-btn-lg pr-0">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color="link" className="p-0">
                                    <UserAvatar size="42" name={`${sub}`}/>
                                    <FontAwesomeIcon className="opacity-8" icon={faAngleDown}/>
                                </DropdownToggle>
                                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                                    <div className="dropdown-menu-header">
                                        <div className="dropdown-menu-header-inner bg-info">
                                            <div className="menu-header-image opacity-2"
                                                 style={{
                                                     backgroundImage: 'url(' + city3 + ')'
                                                 }}
                                            />
                                            <div className="menu-header-content text-left">
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left mr-3">
                                                            <UserAvatar size="48" name={`${sub}`}/>
                                                        </div>
                                                        <div className="widget-content-left">
                                                            <div className="widget-heading">
                                                                {sub}
                                                            </div>
                                                            <div className="widget-subheading opacity-8">
                                                                {email}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Nav vertical>
                                        <NavItem className="nav-item-header">
                                            <Link to={`/admin/perfil`}>
                                                <FontAwesomeIcon icon={faUserCircle}/> Mi Perfil
                                            </Link>
                                        </NavItem>
                                    </Nav>
                                    <Nav vertical>
                                        <NavItem className="nav-item-btn text-center">
                                            <Button className="btn-pill btn-shadow btn-shine"
                                                    color="danger" onClick={logOut}>
                                                <FontAwesomeIcon icon={faPowerOff}/> Cerrar Sesión
                                            </Button>
                                        </NavItem>
                                    </Nav>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                        <div className="widget-content-left  ml-3 header-user-info">
                            <div className="widget-heading">
                                {sub}
                            </div>
                            <div className="widget-subheading">
                                {email}
                            </div>
                        </div>
                        {/*<div className="widget-content-right header-user-info ms-3">*/}
                        {/*    <ThemeOptions/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default UserBox
