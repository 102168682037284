import React, {Suspense, lazy, useEffect} from 'react'
import {Route, Redirect} from 'react-router-dom'
import Loader from 'react-loaders'
import {ApolloClient} from "apollo-client"
import gql from "graphql-tag"
import {ApolloLink, Observable} from 'apollo-link'
import {onError} from 'apollo-link-error'
import {HttpLink} from "apollo-link-http"
import {InMemoryCache} from "apollo-cache-inmemory"
import {useToasts} from "react-toast-notifications"

import {AuthContext} from "../../Context"
import PrivateRoute from '../../PrivateRoute'
import Login from "../../Views/Auth/Login"
import AutomaticLogin from "../../Views/Auth/AutomaticLogin"
import Register from "../../Views/Auth/Register"
import Reset from "../../Views/Auth/Reset"
import Verify from "../../Views/Auth/Verify"
import Home from "../../Views/Home"
import useLocalStorage from "../../hooks/useLocalStorage"
import {isToken} from "../../utils/scripts"

const SlePle51 = lazy(() => import('../../Views/SlePle51'))
const Sire = lazy(() => import('../../Views/Sire'))

require('dotenv').config()
let client

const AppMain = () => {
    const {addToast, removeAllToasts} = useToasts()
    const date = new Date()

    const [auth, setAuth] = useLocalStorage('Auth', {})
    const [localEmpresa, setLocalEmpresa] = useLocalStorage('localempresa', {})
    const [empresa, setEmpresa] = useLocalStorage('empresa', {
        ruc: '', razon: '', direccion: '', operacion: 1,
        periodo: `${date.getFullYear()}-${date.getMonth().toString().padStart(2, '0')}`,
        contenido: true, year: date.getFullYear(), uit: ''
    })
    const [companySire, setCompanySire] = useLocalStorage('CompanySire', {})
    let count = true

    const setClient = auth => {
        setAuth(auth)

        const {authentication} = auth

        client = new ApolloClient({
            link: ApolloLink.from([
                onError(({graphQLErrors, networkError, operation, forward}) => {
                    if (!networkError) return

                    switch (networkError.statusCode) {
                        case 401:
                            // User access token has expired
                            // We assume we have both tokens needed to run the async request
                            // Let's refresh token through async request
                            return new Observable(observer => {
                                client
                                    .query({
                                        query: gql`query refreshToken($authorization: String!) {
                                          refresh(authorization: $authorization) {
                                            authentication
                                            layout
                                          }
                                        }`,
                                        variables: {
                                            authentication
                                        },
                                        context: {headers: {isAuth: isToken()}},
                                        fetchPolicy: "no-cache"
                                    })
                                    .then(response => {
                                        const {refresh} = response.data
                                        if (response.error || refresh === null) {
                                            setAuth({})
                                            window.location.href = '/login'
                                            return
                                        }

                                        operation.setContext({
                                            headers: {
                                                authentication: refresh.authentication,
                                                isAuth: isToken()
                                            }
                                        })

                                        forward(operation)
                                            .subscribe({
                                                next: observer.next.bind(observer),
                                                error: observer.error.bind(observer),
                                                complete: observer.complete.bind(observer)
                                            })

                                        setClient(refresh)
                                    })
                                    .catch(error => {
                                        // No refresh or client token available, we force user to login
                                        observer.error(error)
                                        setAuth({})
                                        window.location.href = '/login'
                                    })
                            })
                        case 402:
                            if (count === true) {
                                toast.error('Nueva sessión con sus credenciales en otro navegador...', {autoClose: 10000})
                                count = false
                                setAuth({})
                                window.location.href = '/login'
                            }
                            break
                        case 403:
                            const {pathname, origin} = window.location
                            removeAllToasts()
                            if (pathname === '/aplicativos/dniplatinum') {
                                let phone = '+51 963 656 425'
                                switch (origin) {
                                    case 'https://www.grupseld.com':
                                    case 'https://grupseld.com':
                                        phone = '+51 959 525 541 / +51 920 553 640'
                                        break
                                    case 'https://www.app.perucontable.net':
                                    case 'https://app.perucontable.net':
                                        phone = '+51 960 219 493'
                                        break
                                }
                                toast.error(`Opción restringida, solicita información al ${phone}`, {autoClose: 10000})
                            } else
                                toast.error('Received status code 403', {autoClose: 10000})
                            break
                    }
                }),
                new HttpLink({
                    uri: `${process.env.REACT_APP_API_CONT}/api`,
                    headers: {
                        authentication,
                        isAuth: isToken()
                    }
                })
            ]),
            cache: new InMemoryCache(),
            name: 'Cliente de GRAPHQL para Aplicativos',
            version: '1.3',
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache'
                }
            }
        })
    }

    const toast = {
        success: (message, {autoClose} = {}) => {
            if (autoClose) addToast(message, {appearance: 'success', autoDismissTimeout: autoClose})
            else addToast(message, {appearance: 'success', autoDismiss: false})
        },
        error: (message, {autoClose}) => {
            if (message.includes('Received status code 403'))
                return addToast(<span>¡Para usar este servicio adquiera un PAQUETE PRO!<a href="/admin/pagos"> AQUÍ</a></span>, {
                    appearance: 'error',
                    autoDismissTimeout: autoClose
                })

            return addToast(message, {appearance: 'error', autoDismissTimeout: autoClose})
        },
        warning: (message, {autoClose}) => addToast(message, {appearance: 'warning', autoDismissTimeout: autoClose}),
        info: (message, {autoClose}) => addToast(message, {appearance: 'info', autoDismissTimeout: autoClose})
    }

    useEffect(() => {
        setClient(auth)
    }, [])

    return (
        client ?
            <AuthContext.Provider
                value={{
                    auth, setAuth, client, setClient, toast, removeAllToasts, empresa, setEmpresa, localEmpresa,
                    setLocalEmpresa, companySire, setCompanySire
                }}>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/automatic/:email/:password" component={AutomaticLogin}/>
                <Route exact path="/register" component={Register}/>
                <Route exact path="/password/reset" component={Reset}/>
                <Route exact path="/password/reset/:token/:user" component={Reset}/>
                <Route exact path="/account/verify/:token" component={Verify}/>
                <PrivateRoute path="/home" component={Home}/>

                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Cargando los Componentes...
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/sleple5.1" component={SlePle51}/>
                </Suspense>

                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Cargando los Componentes...
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/sire" component={Sire}/>
                </Suspense>

                <Route exact path="/" render={() => (
                    <Redirect to={'/login'}/>
                )}/>
            </AuthContext.Provider> : <></>
    )
}

export default AppMain
