module.exports = {
    HomeNav: [
        {
            id: 'NHO001',
            icon: 'pe-7s-home',
            label: 'Inicio',
            to: '/home'
        }
    ],
    SlePle51Nav: [
        {
            id: 'NSP001',
            icon: 'pe-7s-bookmarks',
            label: 'Registro de Compras',
        },
        {
            id: 'NSP001-1',
            parentId: 'NSP001',
            label: 'Compras 8.1',
            to: '/sleple5.1/compras/8.1',
        },
        {
            id: 'NSP001-2',
            parentId: 'NSP001',
            label: 'Compras 8.2',
            to: '/sleple5.1/compras/8.2',
        },
        {
            id: 'NSP001-3',
            parentId: 'NSP001',
            label: 'Compras 8.3',
            to: '/sleple5.1/compras/8.3',
        },
        {
            id: 'NSP002',
            icon: 'pe-7s-notebook',
            label: 'Registro de Ventas',
        },
        {
            id: 'NSP002-1',
            parentId: 'NSP002',
            label: 'Ventas 14.1',
            to: '/sleple5.1/ventas/14.1',
        },
        {
            id: 'NSP002-2',
            parentId: 'NSP002',
            label: 'Ventas 14.2',
            to: '/sleple5.1/ventas/14.2',
        },
        {
            id: 'NSP003',
            icon: 'pe-7s-note2',
            label: 'Libro Diario',
        },
        {
            id: 'NSP003-1',
            parentId: 'NSP003',
            label: 'Libro Diario',
            to: '/sleple5.1/diario/5.1',
        },
        {
            id: 'NSP003-2',
            parentId: 'NSP003',
            label: 'PCGE',
            to: '/sleple5.1/diario/5.3',
        },
        {
            id: 'NSP004',
            icon: 'pe-7s-note2',
            label: 'Libro Diario Simplificado',
        },
        {
            id: 'NSP004-1',
            parentId: 'NSP004',
            label: 'Libro Diario Simplificado',
            to: '/sleple5.1/simplificado/5.2',
        },
        {
            id: 'NSP004-2',
            parentId: 'NSP004',
            label: 'PCGE',
            to: '/sleple5.1/simplificado/5.4',
        },
        {
            id: 'NSP005',
            icon: 'pe-7s-ribbon',
            label: 'Libro Mayor',
            to: '/sleple5.1/mayor/6.1',
        },
    ],
    SireNav: [
        {
            nro: 0,
            id: 'RVIE000',
            icon: 'pe-7s-shopbag',
            label: 'Empresas',
            to: '/sire/companies',
            category: 'SIRE'
        },
        {
            nro: 0,
            id: 'RVIE001',
            icon: 'pe-7s-shopbag',
            label: 'RVIE 14.4',
            category: 'SIRE'
        },
        {
            id: 'RVIE001-1',
            parentId: 'RVIE001',
            label: 'Resumen',
            to: '/sire/rvie/dashboard',
            category: 'SIRE'
        },
        {
            id: 'RVIE001-2',
            parentId: 'RVIE001',
            label: 'CONTABLE',
            to: '/sire/rvie/contable',
            category: 'SIRE'
        },
        {
            id: 'RVIE001-3',
            parentId: 'RVIE001',
            label: 'SIRE-SUNAT',
            to: '/sire/rvie/sunat',
            category: 'SIRE'
        },
        {
            id: 'RVIE001-4',
            parentId: 'RVIE001',
            label: 'Comparar',
            to: '/sire/rvie/comparar',
            category: 'SIRE'
        },
        {
            id: 'RVIE001-5',
            parentId: 'RVIE001',
            label: 'Ajustes Posteriores',
            category: 'SIRE'
        },
        {
            id: 'RVIE001-5-1',
            parentId: 'RVIE001-5',
            label: 'SIRE',
            to: '/sire/rvie/ajustes/sire',
            category: 'SIRE'
        },
        {
            id: 'RVIE001-5-2',
            parentId: 'RVIE001-5',
            label: 'PLE',
            to: '/sire/rvie/ajustes/ple',
            category: 'SIRE'
        },
        {
            id: 'RVIE001-6',
            parentId: 'RVIE001',
            label: 'Historial',
            to: '/sire/rvie/historial',
            category: 'SIRE'
        },
        {
            nro: 0,
            id: 'RCE001',
            icon: 'pe-7s-cart',
            label: 'RCE',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1',
            parentId: 'RCE001',
            icon: 'pe-7s-shopbag',
            label: 'RCE 8.4',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1-1',
            parentId: 'RCE001-1',
            label: 'Resumen',
            to: '/sire/rce/dashboard',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1-2',
            parentId: 'RCE001-1',
            label: 'CONTABLE',
            to: '/sire/rce/contable',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1-3',
            parentId: 'RCE001-1',
            label: 'SIRE-SUNAT',
            to: '/sire/rce/sunat',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1-4',
            parentId: 'RCE001-1',
            label: 'Comparar',
            to: '/sire/rce/comparar',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1-5',
            parentId: 'RCE001-1',
            label: 'Ajustes Posteriores',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1-5-1',
            parentId: 'RCE001-1-5',
            label: 'SIRE',
            to: '/sire/rce/ajustes/sire',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1-5-2',
            parentId: 'RCE001-1-5',
            label: 'PLE',
            to: '/sire/rce/ajustes/ple',
            category: 'SIRE'
        },
        {
            id: 'RCE001-1-6',
            parentId: 'RCE001-1',
            label: 'Historial',
            to: '/sire/rce/historial',
            category: 'SIRE'
        },
        {
            id: 'RCE001-2',
            parentId: 'RCE001',
            icon: 'pe-7s-shopbag',
            label: 'RCE-ND (8.5)',
            to: '/sire/rce/ecocont85',
            category: 'SIRE'
        },
        {
            id: 'RVIE002',
            label: 'Generación de Registros',
            to: '/sire/generar',
            icon: 'pe-7s-check',
            category: 'SIRE'
        },
        {
            id: 'RVIE003',
            label: "Lista de Ticket's",
            to: '/sire/ticket',
            icon: 'pe-7s-note2',
            category: 'SIRE'
        }
    ]
}
