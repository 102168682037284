import React, {Fragment, useState} from "react"
import {Link, Redirect} from "react-router-dom"
import Slider from "react-slick"
import {Col, Row, Button, Form, FormGroup, Label, Input} from 'reactstrap'
import {faUser} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import gql from 'graphql-tag'

import bg1 from '../../assets/utils/images/originals/bg1.jpg'
import bg2 from '../../assets/utils/images/originals/bg2.jpg'
import bg3 from '../../assets/utils/images/originals/bg3.jpg'

import {useAuth} from "../../Context"
import {isToken} from "../../utils/scripts";

const Reset = props => {
    const {token, user} = props.match.params

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true
    }

    const {auth, client, setClient, toast, removeAllToasts} = useAuth()

    const [email, setEmail] = useState(user)
    const [password, setPassword] = useState('')
    const [password_confirm, setPasswordConfirm] = useState('')

    const reset = user ? 'initial' : 'none'

    const postReset = () => {
        if (user) {
            toast.success('Actualizando contraseña...')

            if (email === '') {
                console.log("fromt")
                console.log(email)
                toast.error('Ingrese un Email!', {autoClose: 5000})
                return
            }
            if (password.length < 6 || password.length > 15) {
                toast.error('La contraseña debe ser mayor de 5 y menor de 16 caracteres', {autoClose: 5000})
                return
            }
            if (password !== password_confirm) {
                toast.error('La Contraseña no coincide!', {autoClose: 5000})
                setPassword('')
                setPasswordConfirm('')
                return
            }

            client
                .mutate({
                    mutation: gql`mutation resetPassword($email: String!, $password: String!, $token: String!){
                      passwordReset(email: $email password: $password token: $token) {
                        authentication
                        layout
                      }
                    }`,
                    context: {headers: {isAuth: isToken()}},
                    variables: {
                        email,
                        password,
                        token
                    }
                })
                .then(response => {
                    const {passwordReset} = response.data

                    if (passwordReset.authentication)
                        setClient(passwordReset)

                    removeAllToasts()
                })
                .catch(({message}) => {
                    removeAllToasts()
                    toast.error(message, {autoClose: 2000})
                })
        } else {
            toast.success('Reenviando email...')

            client
                .query({
                    query: gql`query resetPassword($email: String!){
                      reset(email: $email) {
                        authentication
                      }
                    }`,
                    context: {headers: {isAuth: isToken()}},
                    variables: {
                        email
                    },
                    fetchPolicy: "no-cache"
                })
                .then(response => {
                    const {reset} = response.data

                    if (reset === null) {
                        toast.error('¡Ingrese un email Válido!', {autoClose: 10000})
                        return
                    }

                    if (reset.authentication === 'reset') {
                        toast.error('Aún no ha verificado su email!', {autoClose: 10000})
                        return
                    }
                    removeAllToasts()
                    toast.success('¡Te hemos enviado por correo el enlace para restablecer tu contraseña!', {autoClose: 10000})
                })
                .catch(({message}) => {
                    removeAllToasts()
                    toast.error(message, {autoClose: 2000})
                })
        }
    }

    return (
        auth.authentication ?
            <Redirect to={'/home'}/>
            :
            <Fragment>
                <div className="h-100">
                    <Row className="h-100 no-gutters">
                        <Col lg="8" className="d-none d-lg-block">
                            <div className="slider-light">
                                <Slider  {...settings}>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg"
                                             style={{
                                                 backgroundImage: 'url(' + bg1 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Consulta de Validez de CPE</h3>
                                            <p>
                                                Consulte la validez de boletas, facturas y sus respectivas notas
                                                electrónicas.
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg"
                                             style={{
                                                 backgroundImage: 'url(' + bg3 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Pago masivo de detracciones con NPD</h3>
                                            <p>
                                                Operaciones SOL para pago masivo.
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg opacity-6"
                                             style={{
                                                 backgroundImage: 'url(' + bg2 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Consulta DNI, RUC y EsSalud</h3>
                                            <p>
                                                Consultas de reniec, sunat y essalud sin capcha.
                                            </p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                        <Col lg="4" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                                <div className="app-logo"/>
                                <h4 className="mb-0">
                                    <div>Bienvenido,</div>
                                    <span>Restablecer Contraseña</span>
                                </h4>
                                <h6 className="mt-3">
                                    ¿Ya tienes una cuenta?{' '}
                                    <Link to={'/login'} className="text-primary">Login</Link>
                                </h6>
                                <h6 className="mt-3">
                                    ¿Sin cuenta?{' '}
                                    <Link to={'/register'} className="text-primary">Regístrate ahora</Link>
                                </h6>
                                <Row className="divider"/>
                                <Form>
                                    <Row form>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="exampleEmail">Email</Label>
                                                <Input type="email" value={email}
                                                       onChange={e => setEmail(e.target.value)}
                                                       placeholder="Email"/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} style={{display: `${reset}`}}>
                                            <FormGroup>
                                                <Label for="exampleEmail">Nueva Contraseña</Label>
                                                <Input type="password" value={password}
                                                       onChange={e => setPassword(e.target.value)}/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} style={{display: `${reset}`}}>
                                            <FormGroup>
                                                <Label for="exampleEmail">Confirmar Nueva Contraseña</Label>
                                                <Input type="password" value={password_confirm}
                                                       onChange={e => setPasswordConfirm(e.target.value)}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row className="divider"/>
                                <div className="d-flex align-items-center">
                                    <div className="ml-auto">
                                        <Button color="primary" size="lg" onClick={postReset}>
                                            <FontAwesomeIcon icon={faUser}/> Resetear Contraseña
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </div>
            </Fragment>
    )
}

export default Reset